import { AppBar, Toolbar, Typography, Button, Box, List, ListItem, ListItemButton, ListItemText, Drawer, IconButton, Paper, MenuList, MenuItem, Divider, Menu, Collapse } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getCurrentUser, signOut } from '../lib/api/auth';
import { useAuthContext } from "../providers/AuthUser";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import MenuIcon from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import { PageTitle } from './PageTitle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ErrorModal } from './ErrorModal';
import { adminSignOut } from '../lib/api/admin_user';

const drawerWidth = 280;
interface Props {
  window?: () => Window;
}


const Header = (props: Props) =>{
  const { window } = props;
  const authUser = useAuthContext();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [adminMobileOpen, setAdminMobileOpen] = React.useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [adminAnchorEl, setAdminAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const adminMenuOpen = Boolean(anchorEl);
  const [userList, setUserList] = React.useState<any[]>([]);
  const location = useLocation();
  const [mobileUsersOpen, setMobileUsersOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() =>{
    const body = document.getElementsByTagName('body');
    if(Cookies.get('_admin_access_token') && location.pathname.indexOf('/admin') >= 0){
      setIsAdmin(true);
      body[0].style.backgroundColor = '#D19CFC'
    }else{
      setIsAdmin(false);
      body[0].style.backgroundColor = '#F3F3F3'
    }
  }, [location.pathname]);

  const onClickLogout = async()=>{
    await signOut();
    authUser.signout(() =>{
      navigate("/login");
    });
  }

  const onClickAdminLogout = async()=>{
    const res = await adminSignOut();
    if(res.status === 200){
      Cookies.remove('_admin_access_token');
      Cookies.remove('_admin_client');
      Cookies.remove('_admin_uid');
      Cookies.remove('_admin_contact');
      Cookies.remove('_admin_header_text');
      Cookies.remove('adminCurrentUserId');
      navigate('/admin/login')
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleAdminDrawerToggle = () => {
    setAdminMobileOpen((prevState) => !prevState);
  };

  const getUserList = async(openMenu) =>{
    try{
      const ret = await getCurrentUser();
      if(ret?.status == 200){
        const ulist:any[] = [];
        ulist.push({id: ret.data.data.id, email: ret.data.data.email});
        ret.data.users.forEach(u =>{
          ulist.push({id: u.id, email: u.email});
        });
        setUserList(ulist);
        setAnchorEl(openMenu);
      }
    }catch(e){
      console.log(e);
    }
  }

  const getMobileUserList = async() =>{
    try{
      const ret = await getCurrentUser();
      if(ret?.status == 200){
        const ulist:any[] = [];
        ulist.push({id: ret.data.data.id, email: ret.data.data.email});
        ret.data.users.forEach(u =>{
          ulist.push({id: u.id, email: u.email});
        });
        setUserList(ulist);
        setMobileUsersOpen(!mobileUsersOpen);
      }
    }catch(e){
      console.log(e);
    }
  }

  const handleChangeUser = (user) =>{
    if(location.pathname.indexOf('/profile/edit') >= 0 ||
      location.pathname.indexOf('/ar_register') >= 0 ||
      location.pathname.indexOf('/apply/factoring_new') >= 0 ||
      location.pathname.indexOf('/apply/factoring_confirm') >= 0 ||
      location.pathname.indexOf('/factoring/app/draft') >= 0 ||
      location.pathname.indexOf('/apply_copy/factoring') >= 0 ||
      location.pathname.indexOf('/apply_copy/factoring/confirm') >= 0 ||
      location.pathname.indexOf('/add_file/') >= 0
    ){
      setErrorMessage(<Typography>編集中はユーザー切替できません。</Typography>);
      handleOpenErrorModal();
      return;
    }
    const users = JSON.parse(Cookies.get("_users"));
    const changeUser = users.find((u) => u._user_id == user.id);
    if(!changeUser){
      handleClose();
      navigate('/login2?email=' + encodeURIComponent(user.email));
    }else{
      Cookies.set("_access_token", changeUser._access_token);
      Cookies.set("_client", changeUser._client);
      Cookies.set("_uid", changeUser._uid);
      Cookies.set("_email", changeUser._email);
      Cookies.set('currentUserId', changeUser._user_id);
      Cookies.set('isSMSVerified', changeUser._is_sms_verified);
      Cookies.set('_header_text', changeUser._header_text);
      Cookies.set('smsVerifiedAt', changeUser._sms_verified_at);
      navigate(0);
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    getUserList(event.currentTarget);
  };

  const handleAdminClick = (event: React.MouseEvent<HTMLElement>) => {
    navigate('/admin');
  };
  const handleMobileClick = () =>{
    getMobileUserList();
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const loginMenu = (
    <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleClose} id="login-menu" aria-labelledby="login-menu_button">
      <MenuList>
        {
          userList.map((u, idx) =>(
            idx == 0 ?
            <MenuItem key={u.id} disabled>
              <ListItemText>{u.email}</ListItemText>
            </MenuItem>
            :
            <MenuItem key={u.id}>
              <ListItemButton sx={{height: '15px'}} onClick={(e) => handleChangeUser(u)}>
              <ListItemText>{u.email}でログイン</ListItemText>
              </ListItemButton>
            </MenuItem>
          ))
        }
        <Divider />
        <MenuItem>
          <ListItemButton sx={{height: '15px'}} component={Link} to={'/login2'} onClick={handleClose}>
            <ListItemText>ユーザーIDでログインする</ListItemText>
          </ListItemButton>
        </MenuItem>
        <MenuItem>
          <ListItemButton sx={{height: '15px'}} component={Link} to={'/signin'} onClick={handleClose}>
            <ListItemText>別のユーザーIDを追加する</ListItemText>
          </ListItemButton>
        </MenuItem>
      </MenuList>
    </Menu>
  );

  const mobileLoginMenu = (
    <Collapse in={mobileUsersOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {
          userList.map((u, idx) =>(
            idx == 0 ?
            <>
              <ListItem key={u.id} onClick={handleDrawerToggle} sx={{pb: 0, pt: 0, pl: 6}}>
                <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0 }} disabled>
                  <ListItemText>{u.email}</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider variant="middle"/>
            </>
            :
            <>
              <ListItem key={u.id} onClick={handleDrawerToggle} sx={{pb: 0, pt: 0, pl: 6}}>
                <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0 }}  onClick={(e) => handleChangeUser(u)}>
                  <ListItemText>{u.email}でログイン</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider variant="middle"/>
            </>
          ))
        }
        <ListItem onClick={handleDrawerToggle} sx={{pb: 0, pt: 0, pl: 6}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0 }}  component={Link} to={'/login2'}>
            <ListItemText primary="ユーザーIDでログインする" />
          </ListItemButton>
        </ListItem>
        <Divider variant="middle"/>
        <ListItem onClick={handleDrawerToggle} sx={{pb: 0, pt: 0, pl: 6}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0 }}  component={Link} to={'/signin'}>
            <ListItemText primary="別のユーザーIDを追加する" />
          </ListItemButton>
        </ListItem>
        <Divider variant="middle"/>
      </List>
    </Collapse>
  );

  const signedIndrawer = (
    <Box sx={{textAlign: 'center'}}>
      <List>
        <ListItem key='logo'>
          <Box sx={{
            height: 60
          }}>
            <Box component='img' src="/logo_positive_vertical.png" sx={{height: '75%'}}/>
          </Box>
          <div style={{ flexGrow: 1 }}></div>
          <IconButton
            color="success"
            edge="start"
            size="large"
            onClick={handleDrawerToggle}
            sx={{ backgroundColor: '#3D3D3D', borderRadius: 2, textAlign: 'right'}}
          >
            <Close />
          </IconButton>
        </ListItem>
        <Divider />
        <ListItem key='home' onClick={handleDrawerToggle} sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, pt: 0}} component={Link} to={'/'}>
            <Box sx={{width: '10px', height: '10px', background: "#405565", borderRadius: '9px', ml: 1}} />
            <ListItemText primary="トップページ" sx={{ml: 1}}/>
          </ListItemButton>
        </ListItem>
        <Divider variant="middle"/>
        <ListItem key='profile' onClick={handleDrawerToggle} sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, pt: 0}} component={Link} to={'/profile/top'}>
            <Box sx={{width: '10px', height: '10px', background: "#405565", borderRadius: '9px', ml: 1}} />
            <ListItemText primary="ユーザー情報" sx={{ml: 1}}/>
          </ListItemButton>
        </ListItem>
        <Divider variant="middle"/>
        <ListItem key='factoring' onClick={handleDrawerToggle} sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0}} component={Link} to={'/top/factoring'}>
            <Box sx={{width: '10px', height: '10px', background: "#405565", borderRadius: '9px', ml: 1}} />
            <ListItemText primary="ファクタリング申請" sx={{ml: 1}}/>
          </ListItemButton>
        </ListItem>
        <Divider variant="middle"/>
        <ListItem key='file_addiition' onClick={handleDrawerToggle} sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0}} component={Link} to={'/add_file_list'}>
            <Box sx={{width: '10px', height: '10px', background: "#405565", borderRadius: '9px', ml: 1}} />
            <ListItemText primary="追加書類アップロード" sx={{ml: 1}}/>
          </ListItemButton>
        </ListItem>
        <Divider variant="middle"/>
        <ListItem key='sign_up2' sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0}}  onClick={handleMobileClick}>
          <Box sx={{width: '10px', height: '10px', background: "#405565", borderRadius: '9px', ml: 1}} />
            <ListItemText primary="ユーザー切替" sx={{ml: 1}}/>
            {mobileUsersOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Divider variant="middle"/>
        {mobileLoginMenu}
        <ListItem key='terms_of_use' onClick={handleDrawerToggle} sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0}} component={Link} to={'/terms_of_use'}>
            <Box sx={{width: '10px', height: '10px', background: "#405565", borderRadius: '9px', ml: 1}} />
            <ListItemText primary="利用規約" sx={{ml: 1}}/>
          </ListItemButton>
        </ListItem>
        <Divider variant="middle"/>
        <ListItem key='faq' onClick={handleDrawerToggle} sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0}} component={Link} to={'/faq'}>
            <Box sx={{width: '10px', height: '10px', background: "#405565", borderRadius: '9px', ml: 1}} />
            <ListItemText primary="FAQ" sx={{ml: 1}}/>
          </ListItemButton>
        </ListItem>
        <Divider variant="middle"/>
        <ListItem key='logout' onClick={handleDrawerToggle}>
          <ListItemButton sx={{textAlign: 'center', mt: 3}}>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickLogout}
              sx={{borderRadius: 5, minWidth: '155px', width: {md: '50%', xs: '50%'}, fontWeight: 'bold', margin: '0 auto'}}
            >
              ログアウト
            </Button>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  const notSmsDrawer = (
    <Box sx={{textAlign: 'center'}}>
      <List>
        <ListItem key='logo'>
          <Box sx={{height: 60}}>
            <Box component='img' src="/logo_positive_vertical.png" sx={{
              height: '75%'
            }}/>
          </Box>
          <div style={{ flexGrow: 1 }}></div>
          <IconButton
            color="success"
            edge="start"
            size="large"
            onClick={handleDrawerToggle}
            sx={{ backgroundColor: '#3D3D3D', borderRadius: 2, textAlign: 'right'}}
          >
            <Close />
          </IconButton>
        </ListItem>
        <ListItem key='sign_up2' sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0}}  onClick={handleMobileClick}>
            <img src='/icons/icon_off_profile.svg' alt='icon'/>
            <ListItemText primary="ユーザー切替" />
            {mobileUsersOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Divider variant="middle"/>
        {mobileLoginMenu}
        <ListItem key='terms_of_use' onClick={handleDrawerToggle} sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0}} component={Link} to={'/terms_of_use'}>
            <img src='/icons/icon_off_termsofservice.svg' alt='icon'/>
            <ListItemText primary="利用規約" />
          </ListItemButton>
        </ListItem>
        <Divider variant="middle"/>
        <ListItem key='faq' onClick={handleDrawerToggle} sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0}} component={Link} to={'/faq'}>
            <img src='/icons/icon_q_2.svg' alt='icon'/>
            <ListItemText primary="よくある質問" />
          </ListItemButton>
        </ListItem>
        <ListItem key='logout'>
          <ListItemButton sx={{textAlign: 'center', mt: 3}}>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickLogout}
              sx={{borderRadius: 5, minWidth: '155px', width: {md: '50%', xs: '50%'}, fontWeight: 'bold', margin: '0 auto'}}
            >
              ログアウト
            </Button>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  const signedOutDrawer = (
    <Box sx={{textAlign: 'center'}}>
      <List>
        <ListItem key='logo'>
          <Box sx={{height: 60}}>
            <Box component='img' src="/logo_positive_vertical.png" sx={{
              height: '75%'
            }}/>
          </Box>
          <div style={{ flexGrow: 1 }}></div>
          <IconButton
            color="success"
            edge="start"
            size="large"
            onClick={handleDrawerToggle}
            sx={{ backgroundColor: '#3D3D3D', borderRadius: 2, textAlign: 'right'}}
          >
            <Close />
          </IconButton>
        </ListItem>
        <ListItem key='login' onClick={handleDrawerToggle} sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0}} component={Link} to={'/login'}>
            <img src='/icons/icon_off_profile.svg' alt='icon'/>
            <ListItemText primary="ログイン" />
          </ListItemButton>
        </ListItem>
        <ListItem key='sign_up' onClick={handleDrawerToggle} sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0}} component={Link} to={'/signup'}>
            <img src='/icons/icon_off_profile.svg' alt='icon'/>
            <ListItemText primary="新規ユーザー登録" />
          </ListItemButton>
        </ListItem>
        <ListItem key='password_forget' onClick={handleDrawerToggle} sx={{pb: 0, pt: 0}}>
          <ListItemButton sx={{textAlign: 'left', pb: 0, bt: 0}} component={Link} to={'/login/password/resetting'}>
            <img src='/icons/icon_off_profile.svg' alt='icon'/>
            <ListItemText primary="パスワード設定" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )
  return (
    <>
    <AppBar
      position="fixed"
      sx={{
        width: { display: 'flex'},
        borderBottom: {md: '2px solid #F3F3F3'}
      }}
      elevation={0}
    >
      <Toolbar sx={{ backgroundColor: {md: isAdmin ? '#9CFCD1' : '#FFFFFF', xs: '#F3F3F3'}, pl: {md: 0}, border: {xs: '0px', md: '0px'},
      backgroundImage: {md: "none", xs: "url('/icons/sp_header_svg.svg')"},
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: '0px',
      backgroundPositionX: 'calc(100% + 100px)'
      }}>
        <Box component='div' sx={{width: {md: "200px"}, backgroundColor: "#F3F3F3"}}>
        <Box sx={{height: 60}}>
          <Box component='img' src="/logo_positive_vertical.png" sx={{
            cursor: 'pointer',
            height: '75%',
            mt: 1
          }} onClick={(e) => navigate('/')}/>
          </Box>
        </Box>
        {!isAdmin && Cookies.get('isSignedIn') && Cookies.get('isSMSVerified') == 'true' ? 
          <Typography sx={{ color: '#000000', ml: 3, overflow: 'hidden'}}>{Cookies.get('_header_text') == '未設定' ? '' : `${Cookies.get('_header_text')}`}</Typography>
        : isAdmin && location.pathname != "/admin"?
          <Typography sx={{ color: '#000000', ml: 3, overflow: 'hidden'}}>{Cookies.get('_header_text') == '未設定' ? '' : `${Cookies.get('_admin_header_text')}`}</Typography>
        : <></>
        }
        <div style={{ flexGrow: 1 }}></div>
        {!isAdmin && Cookies.get('isSignedIn') ? 
          <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            size="large"
            onClick={handleDrawerToggle}
            sx={{ backgroundColor: '#3D3D3D', borderRadius: 2, display: { md: 'none' }, textAlign: 'right'}}
          >
            <MenuIcon />
          </IconButton>
          <Button onClick={handleClick} color="secondary" variant="contained" sx={{minWidth: '135px', borderRadius: 5, marginRight: 3, display: { xs: 'none', md: 'block'  }}}
            id='login-menu_button'
            aria-controls={menuOpen ? 'login-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}>ユーザー切替</Button>
          {loginMenu}
          <Button  color="primary" variant="contained" sx={{minWidth: '115px', borderRadius: 5, display: { xs: 'none', md: 'block'  }}} onClick={onClickLogout}>ログアウト</Button>
          </>
        : isAdmin ?
          <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            size="large"
            onClick={handleAdminDrawerToggle}
            sx={{ backgroundColor: '#3D3D3D', borderRadius: 2, display: { md: 'none' }, textAlign: 'right'}}
          >
            <MenuIcon />
          </IconButton>
          <Button onClick={handleAdminClick} color="secondary" variant="contained" sx={{minWidth: '135px', borderRadius: 5, marginRight: 3, display: { xs: 'none', md: 'block'  }}}
            id='login-menu_button'
            aria-controls={menuOpen ? 'login-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}>ユーザー切替</Button>
          <Button  color="primary" variant="contained" sx={{minWidth: '115px', borderRadius: 5, display: { xs: 'none', md: 'block'  }}} onClick={onClickAdminLogout}>ログアウト</Button>
          </>
        :
          <></>
        }
      </Toolbar>
      <Toolbar sx={{backgroundColor: '#FFFFFF', display: {xs: 'block', md: 'none'}, color: '#000000', pt: 2}}>
        <PageTitle/>
      </Toolbar>
    </AppBar>
    <nav>
        <Drawer
          container={container}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          anchor='top'
          sx={{
            width: '100%',
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box' },
          }}
        >
          {!isAdmin && Cookies.get('isSignedIn') && Cookies.get('isSignedIn') == 'true' && Cookies.get('isSMSVerified') && Cookies.get('isSMSVerified') == 'true' ? signedIndrawer : <></>}
          {!isAdmin && Cookies.get('isSignedIn') && Cookies.get('isSignedIn') == 'true' && Cookies.get('isSMSVerified') && Cookies.get('isSMSVerified') == 'false' ? notSmsDrawer : <></>}
        </Drawer>
      </nav>
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
      </>
  );
}

export default Header;
